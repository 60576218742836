import loadable from '@loadable/component';
import { shallowEqual, useSelector } from 'react-redux';

import {
  AccountRecoveryContainer,
  CompareCompanyContainer,
  DashboardContainer,
  DeprecatedPortfolioContainer,
  DeprecatedPortfolioEditTxnContainer,
  DeprecatedPortfolioImportContainer,
  DeprecatedPortfolioReturnsContainer,
  OffboardingContainer,
  OopsContainer,
  OTPLoginContainer,
  UserContainer,
} from '@/containers';
import type { Components } from '@/reducers/page';
import { AppUpdateBoundaryRoute } from '@/features/unleash/components/AppUpdateBoundary/components/AppUpdateBoundaryRoute';
import { NarrativeNotFound } from '@/pages/Narrative/components/NarrativeNotFound';

const DiscoverHubContainer = loadable(
  () => import('@/pages/DiscoverHub/DiscoverHubContainer')
);
const CompanyReport = loadable(() => import('@/pages/CompanyReport'));
const AffiliateProgram = loadable(() => import('@/pages/AffiliateProgram'));
const Article = loadable(() => import('@/pages/Article'));
const Market = loadable(() => import('@/pages/Market'));
const MarketHub = loadable(() => import('@/pages/MarketHub'));
const NotFound = loadable(() => import('@/pages/NotFound'));
const Portfolio = loadable(
  () => import('@/features/portfolio/pages/Portfolio'),
  {
    resolveComponent: (c) => c.Portfolio,
  }
);
const MyPortfolios = loadable(
  () => import('@/features/portfolio/pages/MyPortfolios'),
  {
    resolveComponent: (c) => c.MyPortfolios,
  }
);
const NewPortfolio = loadable(
  () => import('@/features/portfolio/pages/NewPortfolio'),
  {
    resolveComponent: (c) => c.NewPortfolio,
  }
);
const DemoPortfolio = loadable(
  () => import('@/features/portfolio/pages/DemoPortfolio'),
  {
    resolveComponent: (c) => c.DemoPortfolio,
  }
);
const PublicPortfolio = loadable(
  () => import('@/features/portfolio/pages/PublicPortfolio'),
  {
    resolveComponent: (c) => c.PublicPortfolio,
  }
);
const DefaultPortfolio = loadable(
  () => import('@/features/portfolio/pages/DefaultPortfolio'),
  {
    resolveComponent: (c) => c.DefaultPortfolio,
  }
);
const PortfolioLinkingSuccess = loadable(
  () => import('@/features/portfolio/pages/PortfolioLinkingSuccess'),
  {
    resolveComponent: (c) => c.PortfolioLinkingSuccess,
  }
);
const PortfolioLanding = loadable(
  () => import('@/features/portfolio/pages/PortfolioLanding'),
  {
    resolveComponent: (c) => c.PortfolioLanding,
  }
);
const PortfolioTransactionsCreate = loadable(
  () => import('@/features/portfolio/pages/Portfolio/components/Transactions'),
  {
    resolveComponent: (c) => c.TransactionsCreate,
  }
);
const PortfolioTransactionsDiscardPortfolio = loadable(
  () => import('@/features/portfolio/pages/Portfolio/components/Transactions'),
  {
    resolveComponent: (c) => c.TransactionsDiscardPortfolio,
  }
);
const PortfolioTransactionsEdit = loadable(
  () => import('@/features/portfolio/pages/Portfolio/components/Transactions'),
  {
    resolveComponent: (c) => c.TransactionsEdit,
  }
);
const PortfolioTransactionsEditHolding = loadable(
  () => import('@/features/portfolio/pages/Portfolio/components/Transactions'),
  {
    resolveComponent: (c) => c.TransactionsEditHolding,
  }
);

const PortfolioTransactionsSaveSuccess = loadable(
  () => import('@/features/portfolio/pages/Portfolio/components/Transactions'),
  {
    resolveComponent: (c) => c.TransactionsSaveSuccess,
  }
);
const DiscoverPageV1 = loadable(() => import('@/pages/DiscoverPageV1'));
const Plans = loadable(() => import('@/pages/Plans'));
const Welcome = loadable(() => import('@/pages/Welcome'));
const TermsAndConditions = loadable(() => import('@/pages/TermsAndConditions'));
const PrivacyPolicy = loadable(() => import('@/pages/PrivacyPolicy'));
const Unsubscribe = loadable(() => import('@/pages/Unsubscribe'));
const Maintenance = loadable(() => import('@/pages/Maintenance'));
const About = loadable(() => import('@/pages/About'));
const Careers = loadable(() => import('@/pages/Careers'));
const Stocks = loadable(() => import('@/pages/Stocks'));
const Screener = loadable(() => import('@/pages/Screener'));
const Podcasts = loadable(() => import('@/pages/Podcasts'));
const Home = loadable(() => import('@/pages/Home'), {
  resolveComponent: (c) => c.Home,
});
const Watchlist = loadable(() => import('@/pages/Watchlist'));
const AccountValidation = loadable(() => import('@/pages/AccountValidation'), {
  resolveComponent: (c) => c.AccountValidation,
});
const Logout = loadable(() => import('@/pages/Logout'), {
  resolveComponent: (c) => c.Logout,
});

const PartnerClaim = loadable(() => import('@/pages/Partner'), {
  resolveComponent: (c) => c.ClaimPage,
});
const PartnerRegister = loadable(() => import('@/pages/Partner'), {
  resolveComponent: (c) => c.RegisterPage,
});
const PartnerLogin = loadable(() => import('@/pages/Partner'), {
  resolveComponent: (c) => c.LoginPage,
});
const PartnerError = loadable(() => import('@/pages/Partner'), {
  resolveComponent: (c) => c.ErrorPage,
});

const PartnerAvanzaClaim = loadable(() => import('@/pages/Partner/Avanza'), {
  resolveComponent: (c) => c.ClaimPage,
});
const PartnerAvanzaRegister = loadable(() => import('@/pages/Partner/Avanza'), {
  resolveComponent: (c) => c.RegisterPage,
});
const PartnerAvanzaLogin = loadable(() => import('@/pages/Partner/Avanza'), {
  resolveComponent: (c) => c.LoginPage,
});
const PartnerAvanzaError = loadable(() => import('@/pages/Partner/Avanza'), {
  resolveComponent: (c) => c.ErrorPage,
});

const Narrative = loadable(() => import('@/pages/Narrative'), {
  resolveComponent: (c) => c.Narrative,
});
const PublicNarrativeCreate = loadable(() => import('@/pages/Narrative'), {
  resolveComponent: (c) => c.PublicNarrativeCreatePage,
});
const PublicNarrativeEdit = loadable(() => import('@/pages/Narrative'), {
  resolveComponent: (c) => c.PublicNarrativeEditPage,
});

const NarrativeUpdateEditPage = loadable(
  () => import('@/pages/Narrative/NarrativeUpdateEdit'),
  {
    resolveComponent: (c) => c.NarrativeUpdateEditPage,
  }
);
const NarrativeCommunityPage = loadable(
  () => import('@/pages/Narrative/pages/NarrativeCommunityPage'),
  {
    resolveComponent: (c) => c.NarrativeCommunityPage,
  }
);
const NarrativeCommunityUpdatePage = loadable(
  () => import('@/pages/Narrative/pages/NarrativeUpdatePage'),
  {
    resolveComponent: (c) => c.NarrativeUpdatePage,
  }
);
const NarrativeCommunityProfilePage = loadable(
  () => import('@/pages/Narrative/pages/NarrativeCommunityProfilePage'),
  {
    resolveComponent: (c) => c.NarrativeCommunityProfilePage,
  }
);

const SubscriptionExtension = loadable(
  () => import('@/pages/SubscriptionExtension'),
  {
    resolveComponent: (c) => c.SubscriptionExtension,
  }
);

const PasswordReset = loadable(() => import('@/pages/PasswordReset'), {
  resolveComponent: (c) => c.PasswordReset,
});

const SocialToLocal = loadable(() => import('@/pages/SocialToLocal'), {
  resolveComponent: (c) => c.SocialToLocal,
});

const EmailChange = loadable(() => import('@/pages/EmailChange'), {
  resolveComponent: (c) => c.EmailChange,
});

const GiftCardRedeem = loadable(() => import('@/pages/GiftCard'), {
  resolveComponent: (c) => c.Redeem,
});

const GiftCardPurchase = loadable(() => import('@/pages/GiftCard/Purchase'), {
  resolveComponent: (c) => c.GiftCardPurchase,
});

const Switcher = () => {
  const current = useSelector<
    { page: { current: Components[keyof Components] } },
    Components[keyof Components]
  >((state) => {
    return state.page.current;
  }, shallowEqual);

  switch (current) {
    case 'NotFound':
      return <NotFound />;
    case 'DiscoverHubContainer':
      return <DiscoverHubContainer />;
    // DiscoverPageV1 = Investing Ideas
    case 'DiscoverPageV1':
      return <DiscoverPageV1 />;
    case 'UserNotificationsContainer':
    case 'UserPlanContainer':
    case 'UserBetaLabContainer':
    case 'UserProfileContainer':
    case 'UserSubscriptionContainer':
    case 'UserSubscriptionAndBillingContainer':
    case 'ProApi':
      return <UserContainer />;
    case 'Plans':
      if (RUNTIME_ENV === 'native') {
        return <UserContainer />;
      } else {
        return <Plans />;
      }
    case 'Home':
      return <Home />;
    case 'Careers':
      return <Careers />;
    case 'About':
      return <About />;
    case 'AccountRecoveryContainer':
      return <AccountRecoveryContainer />;
    case 'CompanyReport':
      return <CompanyReport />;
    case 'CompareCompanyContainer':
      return <CompareCompanyContainer />;
    case 'DashboardContainer':
      return <DashboardContainer />;
    case 'Maintenance':
      return <Maintenance />;
    case 'OTPLoginContainer':
      return <OTPLoginContainer />;
    case 'OffboardingContainer':
      return <OffboardingContainer />;
    case 'OopsContainer':
      return <OopsContainer />;
    case 'Podcasts':
      return <Podcasts />;
    case 'DeprecatedPortfolioContainer':
      return <DeprecatedPortfolioContainer />;
    case 'DeprecatedPortfolioEditTxnContainer':
      return <DeprecatedPortfolioEditTxnContainer />;
    case 'DeprecatedPortfolioImportContainer':
      return <DeprecatedPortfolioImportContainer />;
    case 'DeprecatedPortfolioReturnsContainer':
      return <DeprecatedPortfolioReturnsContainer />;
    case 'MyPortfolios':
      return <MyPortfolios />;
    case 'PrivacyPolicy':
      return <PrivacyPolicy />;
    case 'Screener':
      return <Screener />;
    case 'Stocks':
      return <Stocks />;
    case 'TermsAndConditions':
      return <TermsAndConditions />;
    case 'Watchlist':
      return <Watchlist />;
    case 'Welcome':
      return <Welcome />;
    case 'PartnerRegister':
      return <PartnerRegister />;
    case 'PartnerClaim':
      return <PartnerClaim />;
    case 'PartnerLogin':
      return <PartnerLogin />;
    case 'PartnerError':
      return <PartnerError />;
    case 'PartnerAvanzaRegister':
      return <PartnerAvanzaRegister />;
    case 'PartnerAvanzaClaim':
      return <PartnerAvanzaClaim />;
    case 'PartnerAvanzaLogin':
      return <PartnerAvanzaLogin />;
    case 'PartnerAvanzaError':
      return <PartnerAvanzaError />;
    case 'Article':
      return <Article />;
    case 'MarketHub':
      return <MarketHub />;
    case 'Market':
      return <Market />;
    case 'Unsubscribe':
      return <Unsubscribe />;
    case 'AffiliateProgram':
      return <AffiliateProgram />;
    case 'Portfolio':
    case 'PortfolioHoldings':
    case 'PortfolioNarratives':
    case 'PortfolioAnalysis':
    case 'PortfolioDividends':
    case 'PortfolioReturns':
      return <Portfolio />;
    case 'NewPortfolio':
      return <NewPortfolio />;
    case 'PortfolioTransactionsCreate':
      return <PortfolioTransactionsCreate />;
    case 'PortfolioTransactionsDiscardPortfolio':
      return <PortfolioTransactionsDiscardPortfolio />;
    case 'PortfolioTransactionsEdit':
      return <PortfolioTransactionsEdit />;
    case 'PortfolioTransactionsEditHolding':
      return <PortfolioTransactionsEditHolding />;
    case 'PortfolioTransactionsSaveSuccess':
      return <PortfolioTransactionsSaveSuccess />;
    case 'DemoPortfolio':
      return <DemoPortfolio />;
    case 'PublicPortfolio':
      return <PublicPortfolio />;
    case 'DefaultPortfolio':
      return <DefaultPortfolio />;
    case 'PortfolioLinkingSuccess':
      return <PortfolioLinkingSuccess />;
    case 'AccountValidation':
      return <AccountValidation />;
    case 'Logout':
      return <Logout />;
    case 'PortfolioLanding':
      return <PortfolioLanding />;
    case 'Narrative':
      return (
        <AppUpdateBoundaryRoute flagName="mono-force-update-route-narrative-view">
          <Narrative />
        </AppUpdateBoundaryRoute>
      );
    case 'NarrativeUpdate':
      return (
        <AppUpdateBoundaryRoute flagName="mono-force-update-route-narrative-view">
          <NarrativeCommunityUpdatePage />
        </AppUpdateBoundaryRoute>
      );
    case 'NarrativeLegacy':
      return (
        <AppUpdateBoundaryRoute flagName="mono-force-update-route-narrative-view">
          <NarrativeNotFound />
        </AppUpdateBoundaryRoute>
      );
    case 'PublicNarrativeCreate':
      return (
        <AppUpdateBoundaryRoute flagName="mono-force-update-route-narrative-create">
          <PublicNarrativeCreate />
        </AppUpdateBoundaryRoute>
      );
    case 'PublicNarrativeEdit':
      return (
        <AppUpdateBoundaryRoute flagName="mono-force-update-route-narrative-edit">
          <PublicNarrativeEdit />
        </AppUpdateBoundaryRoute>
      );
    case 'NarrativeUpdateEdit':
      return (
        <AppUpdateBoundaryRoute flagName="mono-force-update-route-narrative-edit">
          <NarrativeUpdateEditPage />
        </AppUpdateBoundaryRoute>
      );
    case 'NarrativeCommunity':
      return (
        <AppUpdateBoundaryRoute flagName="mono-force-update-route-narrative-community">
          <NarrativeCommunityPage />
        </AppUpdateBoundaryRoute>
      );
    case 'NarrativeCommunityProfile':
      return (
        <AppUpdateBoundaryRoute flagName="mono-force-update-route-narrative-community-profile">
          <NarrativeCommunityProfilePage />
        </AppUpdateBoundaryRoute>
      );
    case 'SubscriptionExtension':
      return <SubscriptionExtension />;
    case 'PasswordReset':
      return <PasswordReset />;
    case 'SocialToLocal':
      return <SocialToLocal />;
    case 'EmailChange':
      return <EmailChange />;
    case 'GiftCardRedeem':
      return <GiftCardRedeem />;
    case 'GiftCardPurchase':
      return <GiftCardPurchase />;
    default:
      return <NotFound />;
  }
};
export default Switcher;
