import { Row } from '@simplywallst/ui-core';
import styled from 'styled-components';
import {
  EmblaDots,
  CarouselWrapper,
  EmblaButtonNext,
  EmblaButtonPrev,
  EmblaDot,
} from '@/components/Carousel/styled';

interface Props {
  slides: number;
}

export const ContentBannerRow = styled(Row)<Props>`
  ${EmblaDots} {
    bottom: 55px;
    right: 30px;
    left: unset;
  }

  ${EmblaButtonNext} {
    top: unset;
    bottom: 30px;
    right: ${({ theme }) => theme.x1}px;
    fill: ${({ theme }) => theme.color.white};
  }

  ${EmblaButtonPrev} {
    top: unset;
    left: unset;
    right: ${({ slides }) => 30 + slides * 16}px;
    bottom: 30px;
    fill: ${({ theme }) => theme.color.white};
  }

  ${CarouselWrapper} {
    padding: 0;
    display: flex;
    flex: 1;
  }

  ${EmblaDot} {
    margin-right: 0;
    margin-left: 0;

    &:after {
      width: 4px;
      height: 4px;
    }
  }
`;

export const Img = styled('img')`
  border-radius: ${({ theme }) => theme.x1}px;
  width: 100%;
  height: auto;
  object-fit: contain;
`;
