import { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { ContentBannerRow, Img } from './styled';
import { useBrazeContentCards } from '@/components/Braze/hooks';
import CrossSolid from '@simplywallst/ui-core/icons/CrossSolid';
import { Box, Grid, useTheme, Button } from '@simplywallst/ui-core';
import Carousel, { Slides, Slide } from '@components/Carousel';
import SWSLogo from '@assets/images/logo/sws-logo-no-text.svg';

interface Props {
  feedType: string;
  anchorTarget?: '_self' | '_blank';
  className?: string;
}

export const BrazeContentBanner = ({
  feedType,
  anchorTarget = '_self',
  className = 'braze-content-banner',
}: Props) => {
  const {
    contentCards,
    dismissContentCard,
    clickedContentCard,
    cardImpressions,
  } = useBrazeContentCards(feedType);
  const theme = useTheme();
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (contentCards.length > 0) {
      cardImpressions(contentCards);
    }
  });

  return contentCards !== undefined && contentCards.length > 0 ? (
    <ContentBannerRow slides={contentCards.length} className={className}>
      <Carousel
        showArrows={contentCards.length > 1}
        showDots={contentCards.length > 1}
        totalSlides={contentCards.length}
      >
        <Slides>
          {contentCards.map((card) => (
            <Slide key={card.id}>
              <Box position="relative">
                <a
                  href={card.url}
                  target={anchorTarget}
                  rel="noopener noreferrer nofollow"
                  onClick={() => {
                    clickedContentCard(card);
                    trackEvent({
                      action: 'click',
                      subject: `click-on-content-banner-${feedType}`,
                    });
                  }}
                >
                  <Grid templateColumnsSm="350px" templateColumnsMd="550px">
                    {card.imageUrl ? (
                      <Img src={card.imageUrl} alt="banner" />
                    ) : (
                      <SWSLogo />
                    )}
                  </Grid>
                </a>
                <Box position="absolute" top="-5px" right="-5px">
                  <Button
                    styleType="passive"
                    onClick={() => {
                      dismissContentCard(card);
                      trackEvent({
                        action: 'click',
                        subject: `dismiss-content-banner-${feedType}`,
                      });
                    }}
                  >
                    <CrossSolid fill={theme.color.white} opacity="0.5" />
                  </Button>
                </Box>
              </Box>
            </Slide>
          ))}
        </Slides>
      </Carousel>
    </ContentBannerRow>
  ) : null;
};
